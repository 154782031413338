namespace $ {
	export class $koplenov_rss extends $mol_page {
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= search
		 * 	<= lighter
		 * ```
		 */
		tools() {
			return [
				this.search(),
				this.lighter()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * title \RSS
		 * ```
		 */
		title() {
			return "RSS"
		}
		
		/**
		 * ```tree
		 * body <= posts_view
		 * ```
		 */
		body() {
			return this.posts_view()
		}
		
		/**
		 * ```tree
		 * Post* $mol_link_iconed
		 * 	title <= post_title*
		 * 	uri <= post_link*
		 * ```
		 */
		@ $mol_mem_key
		Post(id: any) {
			const obj = new this.$.$mol_link_iconed()
			
			obj.title = () => this.post_title(id)
			obj.uri = () => this.post_link(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * query?val \
		 * ```
		 */
		@ $mol_mem
		query(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * search $mol_search query?val <=> query?val
		 * ```
		 */
		@ $mol_mem
		search() {
			const obj = new this.$.$mol_search()
			
			obj.query = (val?: any) => this.query(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * lighter $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		lighter() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * posts_view null
		 * ```
		 */
		posts_view() {
			return null as any
		}
		
		/**
		 * ```tree
		 * post_title* \title
		 * ```
		 */
		post_title(id: any) {
			return "title"
		}
		
		/**
		 * ```tree
		 * post_link* \link
		 * ```
		 */
		post_link(id: any) {
			return "link"
		}
	}
	
}

